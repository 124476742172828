<template>
  <v-app>
    <skip-link></skip-link>
    <app-nav />
    <search-drawer />
    <v-main>
      <!-- <corona ref="alert" /> -->
      <router-view />
    </v-main>
    <app-footer />
  </v-app>
</template>

<script>
import AppNav from "@/components/AppNav";
import AppFooter from "@/components/AppFooter";
import SearchDrawer from "@/components/SearchDrawer";
import SkipLink from "@/components/SkipLink";
// import Corona from "@/components/Corona";
export default {
  name: "App",
  components: {
    AppNav,
    SearchDrawer,
    AppFooter,
    SkipLink,
  },
  data: () => ({
    //
  }),
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      if (this.$refs.alert) this.$refs.alert.reset();
    },
  },
  async created() {
    if (!this.$store.state.isAppReady) {
      this.$store.dispatch("init");
    }
  },
  mounted() {
    if (process.env.NODE_ENV === "development") {
    }
    // eslint-disable-next-line vue/script-indent
    // console.log(this.$store.getters.config)
  },
};
</script>
