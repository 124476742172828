var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"noprint",attrs:{"color":"white","fixed":"","height":"90","app":""}},[_c('div',{staticStyle:{"width":"15px"}}),_c('img',{staticClass:"hover",staticStyle:{"margin-left":"-5px","margin-right":"8px"},attrs:{"src":require("@/assets/icjia-logo.png"),"alt":"Illinois Criminal Justice Information Authority","width":_vm.logoWidth()},on:{"click":function($event){_vm.$router.push('/').catch(function (err) {
        _vm.$vuetify.goTo(0);
      })}}}),_vm._v("     "),_c('v-toolbar-title',{staticClass:"heavy hover",on:{"click":function($event){_vm.$router.push('/').catch(function (err) {
        _vm.$vuetify.goTo(0);
      })}}},[_c('span',{staticClass:"agency",staticStyle:{"font-weight":"900","cursor":"pointer"}},[_c('span',{class:{
          'hidden-sm-and-down':
            _vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm,
        }},[_vm._v("ICJIA DOCUMENT")]),_vm._v(" ARCHIVE")])]),_c('div',{staticClass:"flex-grow-1"}),_c('v-toolbar-items',[_c('v-btn',{staticStyle:{"font-weight":"900"},attrs:{"depressed":"","x-large":"","color":"white"},on:{"click":_vm.toggleSearch}},[_c('span',[_vm._v("Search")]),_c('v-icon',{attrs:{"right":""}},[_vm._v(" search ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }