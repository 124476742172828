<template>
  <div>
    <v-hover v-slot:default="{ hover }">
      <v-card
        class="mx-auto white mb-8 pb-8"
        :elevation="hover ? 12 : 2"
        outlined
        style="min-height: 150px"
        @click.stop.prevent="toggleSearch(abbreviation)"
      >
        <v-list-item three-line>
          <div style="width: 150px" class="hidden-sm-and-down text-center">
            <v-list-item-avatar tile :min-width="width" :height="height">
              <v-img :src="require(`@/assets/${logo}`)" />
            </v-list-item-avatar>
          </div>
          <v-list-item-content>
            <v-list-item-title
              class="headline mt-3 mb-1"
              style="font-weight: 700"
            >
              {{ title }}
            </v-list-item-title>

            <div v-if="buttons.length" class="mt-2">
              <div
                v-for="(button, index) in buttons"
                :key="index + 'button'"
                style="
                  font-weight: 900;
                  font-size: 13px;
                  text-decoration: underline;
                  color: CornflowerBlue;
                "
                @click.stop.prevent="goToURL(button.href)"
              >
                {{ button.href }}
              </div>
            </div>

            <div class="mt-5" v-html="content" />
          </v-list-item-content>
        </v-list-item>
        <v-card-actions>
          <v-spacer />

          <div v-if="buttons.length">
            <span v-for="(button, index) in buttons" :key="index"
              >&nbsp;&nbsp; &nbsp;&nbsp;
              <v-btn
                v-if="button && button.routing !== 'remote'"
                @click="toggleSearch(abbreviation)"
                x-small
                depressed
                style="font-weight: 900"
              >
                {{ button.text
                }}<v-icon right small>
                  {{ button.icon }}
                </v-icon>
              </v-btn>
            </span>
          </div>
        </v-card-actions>
      </v-card>
    </v-hover>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus.js";
export default {
  props: {
    logo: {
      type: String,
      default: "state-seal-color.png",
    },
    abbreviation: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "Title here",
    },
    content: {
      type: String,
      default: "Content here",
    },
    width: {
      type: Number,
      default: 100,
    },
    height: {
      type: Number,
      default: 100,
    },
    path: {
      type: String,
      default: "https://archive.icjia.cloud/files/",
    },
    buttons: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {},
  methods: {
    goToURL(href) {
      window.open(href, "_blank", "noopener,noreferrer");
    },
    toggleSearch(abbreviation) {
      //console.log(abbreviation);
      EventBus.$emit("openSearch");
      EventBus.$emit("selectAgency", abbreviation);
    },
    gotoFolder(path) {
      // console.log(`${this.$store.getters.config.archiveURL}`)
      location.href = `${path}`;
    },
  },
};
</script>

<style>
p {
  line-height: 1.5 !important;
}
</style>
